'use client'

import { FC } from 'react'

import Link from '@components/link/link'

const ClintSideLinks: FC = () => {
  return (
    <>
      <Link href={`/terms`}>Terms & Conditions</Link>
      &nbsp;|&nbsp;
      <Link href={`/privacy`}>Privacy Policy</Link>
      &nbsp;|&nbsp;
      <Link href={`/ai-agent`}>AI Agent</Link>
      &nbsp;|&nbsp;
      <Link href={`/jobs`}>Browse Jobs</Link>
      &nbsp;|&nbsp;
      <Link href={`/faq`}>FAQ</Link>
    </>
  )
}

export default ClintSideLinks
