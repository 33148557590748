import { getImageProps } from 'next/image'

import type { BlogPost } from '@types'

import Link from '@components/link'
import { getImageSrc, seoUrlPathEncode } from '@lib/helpers/client'
import getBackgroundImage from '@lib/helpers/cross-env/get-background-image'

import styles from './blog-post.module.scss'

export type PostProps = {
  post: BlogPost
}

export default function Post({ post }: PostProps): JSX.Element {
  const linkToBlog = `/blogs/${seoUrlPathEncode(post.title, 'blog')}-${post.id}`

  const imageUrl = getImageSrc(post.image?.sizes, 'POST-THUMBNAIL')

  const imgProps = getImageProps({
    src: imageUrl || '/images/article/blog-placeholder.png',
    alt: post.title || 'SonicJobs Blog Post Featured Image',
    width: 350,
    height: 233,
    priority: false,
    loading: 'lazy',
  })

  const style = {
    backgroundImage: getBackgroundImage(imgProps.props.srcSet),
  }

  let { excerpt } = post

  if (excerpt.length > 100) {
    excerpt = `${excerpt.substring(0, 97)}[...]`
  }

  return (
    <Link href={linkToBlog} className={styles.post}>
      <div className={styles['img-container']} style={style}></div>
      <h3>{post.title}</h3>
      <p>{excerpt}</p>
    </Link>
  )
}
