'use client'

import { useCallback, useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import type { BlogPost, CarouselBreakPoint } from '@types'

import useAddEventListener from '@hooks/useAddEventListener'

import Post from '../blog-post'

const Glider = dynamic(() => import('react-glider'))

export default function Carousel({ posts }: { posts: BlogPost[] }): JSX.Element {
  const [mobile, setMobile] = useState<boolean>(false)

  const handleResize = useCallback((): void => {
    const width = window.innerWidth
    setMobile(width <= 1060)
  }, [])

  useEffect(() => {
    handleResize()
  }, [handleResize])

  useAddEventListener('resize', handleResize)

  const responsive: CarouselBreakPoint[] = [
    { breakpoint: 1, settings: { slidesToShow: 1, slidesToScroll: 'auto' } },
    { breakpoint: 562, settings: { slidesToShow: 2, slidesToScroll: 'auto' } },
    { breakpoint: 816, settings: { slidesToShow: 3, slidesToScroll: 'auto' } },
    { breakpoint: 1060, settings: { slidesToShow: 4, slidesToScroll: 'auto' } },
  ]

  return (
    <Glider
      draggable={mobile}
      id="posts"
      hasDots={mobile}
      duration={1.5}
      responsive={responsive}
      resizeLock
      scrollLock={!mobile}
    >
      {posts?.map((item: BlogPost) => (
        <Post post={item} key={item?.id} />
      ))}
    </Glider>
  )
}
